import { Route } from 'react-router';
import {Redirect} from "react-router-dom";
import {useSelector} from "react-redux";

function PublicRoute({ component: Component , ...children } : any) : JSX.Element  {

    const user = useSelector((state : any) => state.user );

    if (user.id === 0) {
        return <Route {...children} render={props => <Component {...props} />} />;
    }
    return <Redirect to="/Home" />;
}

export default PublicRoute;