import { ADD_IMAGE } from './actionsTypes';


export default function image(
  state: any = null,
  action: any,
) {
  switch (action.type) {
    case ADD_IMAGE:
        return {
            ...action.payload
          }
    default:
        return state;
  }
}
