import {ApiResponseError} from "../models/NewApiResponse";
import axios, {AxiosError} from "axios";
import {ApiErrorFormat} from "../models/ApiErrorFormat";

export const hasErrors = (errors: any): any => {
  return Object.keys(errors).some(key => {
    const value = errors[key];

    if (value && typeof value === 'object') {
      return hasErrors(value)
    }
    return typeof value !== 'undefined'
  })
};

export const getErrorFormat = (error: any): ApiResponseError => {
  let status = 500;
  let errorCode = "GENERIC_ERROR";
  let errorMessage = "Error de red, no se pudo conectar al servidor.";
  let payload = undefined;
  let delimiter = undefined;

  if (axios.isAxiosError(error)) {
    const axiosError = error as AxiosError;
    if (axiosError.response) {
      const dataError = (axiosError?.response?.data as any)?.data as ApiErrorFormat;

      if (dataError) {
        status = dataError.code;
        if(dataError.errorCode && dataError.message) {
          errorCode = dataError.errorCode;
          errorMessage = dataError.message;
          payload = dataError.payload || payload;
          delimiter = dataError.delimiter;
        }else {
          errorCode = "API_BAD_FORMAT_ERROR";
          errorMessage =
              "Error, obtuvo una respuesta sin el formato correcto desde servidor.";
        }
      } else {
        errorCode = "API_BAD_EMPTY_CONTENT_RESPONSE";
        errorMessage =
            "Error, obtuvo una respuesta vacía desde servidor.";
      }

      // Error de respuesta del servidor (4xx o 5xx)
      console.log(
          `Error: ${axiosError.response.status} - ${axiosError.response.statusText}`,
          dataError
      );
    } else if (axiosError.request) {
      // Error de solicitud (sin respuesta del servidor)
      errorCode = "SERVER_NO_RESPONSE_ERROR";
      errorMessage =
          "Error de solicitud, no se recibió respuesta del servidor.";
    } else {
      // Otros errores
      errorCode = "APPLICATION_PROCESS_ERROR";
      errorMessage = "Error al procesar la solicitud.";
    }
  }

  return {
    success: false,
    status,
    data: {
      code: errorCode,
      message: errorMessage,
      payload,
      delimiter
    },
  };
}

export const countries = [
    { "alpha3Code": "USA", "code": "US" },
    { "alpha3Code": "CHL", "code": "CL" },
    { "alpha3Code": "MEX", "code": "MX" },
    { "alpha3Code": "PER", "code": "PE" },
    { "alpha3Code": "COL", "code": "CO" }
];
