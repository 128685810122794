const  setItem = async (key:string, value: any) => {
    await localStorage.setItem(key, value);
}

const getItem = (key:string) => {
    return localStorage.getItem(key);
}

const removeItem = (key:string) => {
    return localStorage.removeItem(key);
}

const setToken = (userToken:string) => {
    sessionStorage.setItem('token', JSON.stringify(userToken))
  }


  const removeToken = () => {
    sessionStorage.removeItem('token');
  }

const getToken = () => {
    const tokenString = sessionStorage.getItem('token');
    var userToken;
    if(tokenString) {
        userToken = JSON.parse(tokenString);
    }
    return userToken
} 

export {setItem, getItem, setToken, getToken, removeToken, removeItem};