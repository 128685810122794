import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { makeStore } from './redux/reducers';
import App from './App';
import { StoreContext } from 'redux-react-hook';
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";

import './App.scss';

const { persistor, store } = makeStore();

ReactDOM.render(
    <StoreContext.Provider value={store}>
        <PersistGate
            loading={<></>}
            persistor={persistor}
        ><Provider store={store}>
                <App />
            </Provider>
        </PersistGate>

    </StoreContext.Provider>
    , document.getElementById('root'));

serviceWorker.unregister();
