import { SAVE, FETCH, UPDATE, REMOVE } from './actionTypes';
import IHelper from '../../models/helper.models';
import { IActionHelper } from '../../models/action.model';


const defaultStore: IHelper = {
  localCountry: { code: '' },
}

export default function helperReducer(
  state: IHelper = defaultStore,
  action: IActionHelper,
) {
  switch (action.type) {
    case SAVE:
      return {
        localCountry: action.payload,
      };

    case FETCH:
      return {
        ...state
      };

    case UPDATE:
      return {
        ...state,
        ...action.payload
      }

    case REMOVE:
      return {
        ...state,
        localCountry: { code: ''},
      }

    default:
      return state;
  }
}