import { UPDATEFORMYPROFILE, UPDATEFORMYDATABANK } from './actionTypes';
import { IProfileAction } from '../../models/action.model';

const defaultStore: any = {
  myProfile: {
    email: '',
    //myCertificarte: '',
    region: '',
    comuna: '',
    direccion: '',
    celular: ''
  },
  myDataBank: {
    bank: 0,
    acountBank: 0,
    acountNumber: ''
  },
}

export default function formDataPorfile(
  state: any = defaultStore,
  action: IProfileAction,
) {
  switch (action.type) {

    case UPDATEFORMYPROFILE:
      return {
        ...state,
        myProfile: action.payload
      }

    case UPDATEFORMYDATABANK:
      return {
        ...state,
        myDataBank: action.payload
      }

    default:
      return state;
  }
}
