import { Route } from 'react-router';
import {Redirect, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {useEffect} from "react";

declare global {
    interface Window {
        _hsq: { push: (callParam: any) => void },
        hsConversationsSettings: any
    }
}

function PrivateRoute({ component: Component , ...children } : any) : JSX.Element  {
    const user:any = useSelector((state : any) => state.user );
    const location = useLocation();
    useEffect(() => {
        if (user?.id !== 0) {
            // window._hsq.push(["setPath", location.pathname]);
            // window._hsq.push(['trackPageView']);
        }
    }, [user, location]);

    if (user.id !== 0) {
        return <Route {...children} render={props => <Component {...props} />} />;
    }
    return <Redirect to="/sign-in" />;
}

export default PrivateRoute;