import axios, {AxiosInstance} from "axios";
import {API_GO_BY_URL} from "../config/constants";

const api: AxiosInstance = axios.create({
    baseURL: API_GO_BY_URL,
    headers: {
        "Accept": 'application/json',
        "Content-type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        lang: 'es'
    },
});

export default api;
