import { SAVE, FETCH, UPDATE, REMOVE } from './actionTypes';
import { IAction } from '../../models/action.model';
import { DEFAULT_COUNTRY } from "../../../config/constants";


const defaultStore: any = {
  id: 0,
  email: '',
  roleId: '',
  token: '',
  hubspotVisitorToken: '',
  countryCode: DEFAULT_COUNTRY,
  avatarBase64Encoded: null,
  profile: undefined
}

export default function user(
  state: any = defaultStore,
  action: IAction,
) {
  switch (action.type) {
    case SAVE:
      return {
        id: action.payload.id,
        email: action.payload.email,
        roleId: action.payload.roleId,
        countryCode: action.payload.countryCode,
        token: action.payload.token,
        hubspotVisitorToken: action.payload.hubspotVisitorToken,
        avatarBase64Encoded: action.payload.avatarBase64Encoded,
        profile: action.payload.profile,
      };

    case FETCH:
      return {
        ...state
      };

    case UPDATE:
      return {
        ...state,
        ...action.payload
      }

    case REMOVE:
      return {
        ...state,
        ...defaultStore
      }

    default:
      return state;
  }
}
