import React, { lazy, Suspense } from "react";
import { BrowserRouter, Redirect, Switch } from "react-router-dom";
import PublicRoute from "./components/PublicRoute";
import PropTypes from "prop-types";
import { ModalProvider } from "react-simple-hook-modal";
import Route from "./components/Route";
import PrivateRoute from "./components/PrivateRoute";

export default function  Routes({ auth }: any) {

  //Pages
  const SignIn = lazy(() => import('../scenes/SignIn'));
  const SignUp = lazy(() => import('../scenes/SingUp'));
  const Login = lazy(() => import('../scenes/SignIn/pages/Login'));
  const SelectCountry = lazy(() => import('../scenes/SingUp/components/SelectCountry'));
  const VerifyAccount = lazy(() => import('../scenes/SingUp/components/VerifyAcconunt'));
  const CreatePassword = lazy(() => import('../scenes/SingUp/components/CreatePassword'));
  const ForgotPassword = lazy(() => import('../scenes/ForgotPassword/pages/Recover'));
  const NewPassword = lazy(() => import('../scenes/ForgotPassword/pages/NewPassword'));
  const OnBoardingLanding = lazy(() => import('../scenes/Onboarding/pages/Landing'));
  const OnBoarding = lazy(() => import('../scenes/Onboarding'));
  const Home = lazy(() => import('../scenes/Home'));
  const Fail = lazy(() => import('../scenes/Home/components/Fail'));
  const Check = lazy(() => import('../scenes/Home/components/CheckMetamap'));
  const Convocatorias = lazy(() => import('../scenes/Convocatorias'));
  const Postulaciones = lazy(() => import('../scenes/Postulaciones'));
  const Profile = lazy(() => import('../scenes/Profile'));
  const Notifications = lazy(() => import('../scenes/Notifications/pages/index'));
  const Inbox = lazy(() => import('../scenes/Inbox/index'));
  const Asistencias = lazy(() => import('../scenes/Asistencias/index'));

  return (
    <BrowserRouter>
      <Suspense fallback={<></>}>
        <ModalProvider backdropClassName="modal-provider__main">
          <Switch>
            <Route exact path="/" >
              {auth.id === 0 ? <Redirect to="/sign-in" /> : <Redirect to="/Home" />}
            </Route>
            <PublicRoute exact path="/sign-in" component={SignIn} />
            <PublicRoute exact path="/sign-up" component={SignUp} />
            <PublicRoute exact path="/select-Country" component={SelectCountry} />
            <PublicRoute exact path="/verify-account" component={VerifyAccount} />
            <PublicRoute exact path="/create-password/:token" component={CreatePassword} />
            <PublicRoute exact path="/forgot-password" component={ForgotPassword} />
            <PublicRoute exact path="/new-password/:token" component={NewPassword} />
            <PrivateRoute exact path="/onBoading/landing" component={OnBoardingLanding} />
            <PrivateRoute exact path="/onBoading" component={OnBoarding} />
            <PrivateRoute exact path="/Notifications" component={Notifications} />
            <PrivateRoute exact path="/inbox" component={Inbox} />
            <PrivateRoute exact path="/inbox/:section" component={Inbox} />
            <PublicRoute exact path="/login" component={Login} />
            {/* <PrivateRoute exact path="/Home" component={Home} /> */}
            <PrivateRoute exact path="/Home" component={Home} />
            <PrivateRoute exact path="/Home/fail" component={Fail} />
            <PrivateRoute exact path="/Home/check" component={Check} />
            <PrivateRoute exact path="/asistencias/:section" component={Asistencias} />
            <PrivateRoute exact path="/asistencias" component={Asistencias} />
            <PrivateRoute exact path="/postulaciones" component={Postulaciones} />
            <PrivateRoute exact path="/postulaciones/:section" component={Postulaciones} />
            <PrivateRoute exact path="/postulaciones/:section/:id" component={Postulaciones} />
            <PrivateRoute exact path="/profile/:section" component={Profile} />
            <PrivateRoute exact path="/profile" component={Profile} />
            <PrivateRoute exact path="/convocatorias/:section" component={Convocatorias} />
            <PrivateRoute exact path="/convocatorias" component={Convocatorias} />
            <PrivateRoute exact path="/convocatorias/:section/:id" component={Convocatorias} />
          </Switch>
        </ModalProvider>
      </Suspense>
    </BrowserRouter>
  );
}

Routes.propTypes = {
  auth: PropTypes.object.isRequired
};
